<template>
  <div class="register">
    <div class="content">
      <div class="register__row">
        <RegisterSteps
          :step="step"
          :title="'Обновление профиля'"
          :stepList="stepList"
        />
        <UpdateForm @changeStep="changeStep" />
      </div>
    </div>
  </div>
</template>

<script>
import UpdateForm from "../../components/auth/UpdateForm.vue";
import RegisterSteps from "../../components/auth/RegisterSteps.vue";
import { mapActions } from 'vuex';

export default {
  metaInfo: {
      title: 'Регистрация через mdm_id',
  },
  name: "UserUpdate",
  components: { RegisterSteps, UpdateForm },
  data: () => ({
    step: 1,
    stepList: ["Шаг 1. Почта и телефон", "Шаг 2. Пароль"],
  }),
  methods: {
    changeStep(stepNumber) {
      this.step = stepNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  height: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    padding: 40px 0;
    height: auto;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>